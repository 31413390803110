import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Grid, TextField, MenuItem, Avatar } from '@material-ui/core';

import { userService } from '#root/services';
import { useNotification, useAuth } from '#root/hooks';
import { GuveryTextField, Notification } from '#root/components';
import { GuveryLayout } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import countriesArray from '#root/constants/countries';
import * as VARIABLES from '#root/constants/variables';
import * as ROUTES from '#root/constants/navigation';

import styles from './userTravelerSettings.module.scss';

const documentsArray = ['DNI', 'Pasaporte'];

function UserTravelerSettings() {
  const { usuario } = useAuth();
  const { notification, createMessage, closeMessage } = useNotification();
  const [viewDisable, setViewDisable] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [settings, setSettings] = useState({
    pais_residencia: '',
    tipo_documento: '',
    numero_documento: '',
  });

  const handleUserInputChange = (e) => {
    setSettings({
      ...settings,
      [e.target.name]: e.target.value,
    });
  };

  const validateSettings = () => {
    if (settings.numero_documento.length <= 7) {
      setBtnDisable(true);
      return;
    }
    if (!settings.pais_residencia || !settings.tipo_documento || !settings.numero_documento) {
      setBtnDisable(true);
      return;
    }
    setBtnDisable(false);
  };

  const handleUpdateSettings = async (e) => {
    e.preventDefault();
    fetchUpdateSettings();
  };

  const fetchUpdateSettings = async () => {
    setBtnDisable(true);
    const res = await userService.addTravelerSettings(settings);
    if (res.error) {
      createMessage.error(res.message);
      setBtnDisable(false);
      return;
    }
    createMessage.success(res.message);
    setTimeout(() => {
      // history.push(ROUTES.USER_PROFILE);
      window.location.href = VARIABLES.URL_PHP_PAGE + 'usuario/perfil';
    }, 1000);
    setBtnDisable(false);
  };

  const fillSettingsData = () => {
    if (usuario.numdoc && usuario.paidoc && usuario.tipdoc) {
      setViewDisable(true);
      setSettings({
        pais_residencia: usuario.paidoc,
        tipo_documento: usuario.tipdoc,
        numero_documento: usuario.numdoc,
      });
    }
  };

  useEffect(() => {
    validateSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    fillSettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (usuario.numdoc && usuario.paidoc && usuario.tipdoc) {
    return <Redirect to={ROUTES.USER_PROFILE} />;
  }

  return (
    <GuveryLayout>
      <div className={styles.title}>
        <h1>Configuración de Viajero</h1>
        <p>1. Completa la información adicional requerida.</p>
        <p>
          2. Luego, agrega tu foto de perfil (desde{' '}
          <a className="guv-no-decoration" href={VARIABLES.URL_PHP_PAGE + 'usuario/perfil'}>
            Mi Perfil
          </a>
          ).
        </p>
        <p>3. Finalmente crea tu viaje y capacítate para poder iniciar con las ofertas.</p>
      </div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <GuveryPaper disableHover disableElevation className={styles.box}>
            <div className={styles.header}>
              <div>
                <h4>Información adicional:</h4>
                <p>Se requiere esta información solo para los viajeros</p>
              </div>
              <span className={styles.link}>
                ¿Tienes problemas?{' '}
                <a
                  className="guv-no-decoration"
                  href="https://m.me/guveryoficial"
                  target="_blank"
                  rel="noopener noreferrer">
                  Avísanos
                </a>
              </span>
            </div>
            <form onSubmit={handleUpdateSettings}>
              <div className={styles.content}>
                <TextField
                  select
                  fullWidth
                  disabled={viewDisable}
                  variant="outlined"
                  id="pais_residencia"
                  name="pais_residencia"
                  label="Pais"
                  type="text"
                  value={settings.pais_residencia}
                  onChange={handleUserInputChange}>
                  {countriesArray.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      <div className={styles.iconWrap}>
                        <Avatar
                          className={styles.icon}
                          src={option.file_url}
                          alt={option.name}
                        />
                        {option.name}
                      </div>
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  fullWidth
                  disabled={viewDisable}
                  variant="outlined"
                  id="tipo_documento"
                  name="tipo_documento"
                  label="Tipo de documento"
                  type="text"
                  value={settings.tipo_documento}
                  onChange={handleUserInputChange}>
                  {documentsArray.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <GuveryTextField
                  disabled={viewDisable}
                  id="numero_documento"
                  name="numero_documento"
                  label="Número de identidad"
                  type="text"
                  value={settings.numero_documento}
                  onChange={handleUserInputChange}
                />
                {/*
                  <GuveryTextField id="wsp" label="Teléfono" type="number"></GuveryTextField>
                  */}
              </div>
              <div className={styles.submit}>
                <Button
                  disableElevation
                  disabled={viewDisable ? true : btnDisable}
                  variant="contained"
                  color="primary"
                  type="submit">
                  Guardar cambios
                </Button>
              </div>
            </form>
          </GuveryPaper>
        </Grid>
        <span className={styles.terms}>
          * Tu información nunca será brindada a terceros y está totalmente protegida. Lee
          nuestra{' '}
          <a
            href={VARIABLES.URL_PHP_PAGE + 'terminos'}
            target="_blank"
            rel="noopener noreferrer"
            className="guv-no-decoration">
            política de seguridad
          </a>
          .
        </span>
      </Grid>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </GuveryLayout>
  );
}

export default UserTravelerSettings;

import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import TripDTO from '#root/models/TripDTO';
import { tripService } from '#root/services';
import { GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import {
  BriefcaseOutlineIcon,
  HandHoldingIcon,
  InboxIcon,
  MapSolidIcon,
  PlaneSolidIcon,
  PinSolidIcon,
} from '#root/icons';
import styles from './tripCard.module.scss';

const amountColor = {
  blue: styles.boxBlue,
  green: styles.boxGreen,
  orange: styles.boxOrange,
};

interface BoxAmountProps {
  icon: React.ReactNode;
  title?: string;
  value?: string | number;
  color?: 'blue' | 'green' | 'orange';
}

const BoxAmount = (props: BoxAmountProps) => {
  const { icon, title, value, color } = props;
  const colorClass = amountColor[color];
  return (
    <div className={clsx(styles.badge, colorClass)}>
      <div className={styles.icon}>{icon}</div>
      {title && (
        <div className={styles.body}>
          <p>{title}</p>
          <h6>{value ? value : 0}</h6>
        </div>
      )}
    </div>
  );
};

interface TravelItemProps {
  icon: React.ReactNode;
  title?: string;
  subTitle?: Date | string | number;
  subTitleBold?: Date | string | number;
}

const TravelItem = (props: TravelItemProps) => {
  const { icon, title = '', subTitle = '', subTitleBold = '' } = props;
  return (
    <div className={styles.travelItem}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.info}>
        <h6 className="guv-ellipsis">{title}</h6>
        <p>{subTitle}</p>
        <p>
          <strong>{subTitleBold}</strong>
        </p>
      </div>
    </div>
  );
};

const Status = ({ text }) => {
  return (
    <div className={clsx(styles.state, styles[text])}>
      <span>{text}</span>
    </div>
  );
};

interface TripCardProps {
  trip: TripDTO;
  createMessage: any;
  fetchTrips: () => void;
  openDialog: () => void;
}

const TripCard = (props: TripCardProps) => {
  const { trip, createMessage, fetchTrips, openDialog } = props;
  const destinyText =
    trip.destination_country && `${trip.destination_country} - ${trip.lugar_entrega}`;

  const fetchSelectTrip = async () => {
    const res = await tripService.select(trip.id);
    if (!res.error) {
      createMessage.success('Viaje seleccionado');
    } else {
      createMessage.error(res.message);
    }
    fetchTrips();
  };

  return (
    <GuveryPaper disableElevation disableHover>
      <div className={styles.header}>
        <GuverySpace wrap align="center">
          <h4>{trip.nombre}</h4>
          <Status text={trip.estado} />
          {trip.is_seleccionado === 1 && <Status text="SELECCIONADO" />}
        </GuverySpace>
        <GuverySpace wrap align="center">
          {trip.estado === 'CREADO' && (
            <Button disableElevation variant="outlined" color="secondary" onClick={openDialog}>
              Editar
            </Button>
          )}
          {trip.is_seleccionado !== 1 && trip.estado === 'CREADO' && (
            <Button
              disableElevation
              variant="outlined"
              color="primary"
              onClick={fetchSelectTrip}>
              Seleccionar
            </Button>
          )}
        </GuverySpace>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <TravelItem
            icon={<PinSolidIcon />}
            title={trip.departure_country}
            subTitle={trip.city}
          />
          <TravelItem
            icon={<PlaneSolidIcon className={styles.rotate} />}
            subTitle={trip.arrive_date}
          />
          <TravelItem
            icon={<MapSolidIcon />}
            title={destinyText}
            subTitleBold={trip.fecha_entrega + ' (entrega)'}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.right}>
          <div className={styles.group}>
            <p className={styles.detail}>
              <span className={styles.detailTitle}>Alojamiento: </span>
              {trip.accommodation_type}
            </p>
            <p className={styles.detail}>
              <span className={styles.detailTitle}>Código postal: </span>
              {trip.zipcode}
            </p>
            <p className={styles.detail}>
              <span className={styles.detailTitle}>Cantidad de adultos: </span>
              {trip.adults_qty}
            </p>
            <p className={styles.detail}>
              <span className={styles.detailTitle}>Fecha max. Recepción: </span>
              {trip.max_reception_date}
            </p>
          </div>
          <div className={styles.badg}>
            <GuverySpace wrap direction="horizontal" size="small">
              <BoxAmount
                icon={<InboxIcon />}
                title="Ofertas"
                value={trip.offers_count}
                color="blue"
              />
              <BoxAmount
                icon={<BriefcaseOutlineIcon />}
                title="Acuerdos "
                value={trip.deals_count}
                color="orange"
              />
              <BoxAmount
                icon={<HandHoldingIcon />}
                title="Pagados"
                value={trip.deals_paid_count}
                color="green"
              />
            </GuverySpace>
          </div>
        </div>
      </div>
    </GuveryPaper>
  );
};

export default TripCard;

import { Fragment, SyntheticEvent, useState, useEffect, useRef, useCallback } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { Avatar, Button, Grid, Chip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IconSearch, IconRefresh, IconCheck } from '@tabler/icons';
import { dealService } from '#root/services';
import { useResponsive, useNotification, useDialog } from '#root/hooks';
import { Notification, SearchDialog } from '#root/components';
import { GuveryPaper, GuveryEmpty } from '#root/ui-components/cards';
import {
  GuveryLayout,
  GuveryPageHeader,
  GuveryTab,
  GuveryTabPanel,
  GuveryTabs,
} from '#root/ui-components';
import {
  TRAVELER_OFFERS,
  TRAVELER_DEALS,
  TRAVELER_DEAL_AUTO,
  TRAVELER_DEALS_CREATED,
  TRAVELER_DEALS_DELIVERED,
  TRAVELER_DEALS_CANCELED,
} from '#root/constants/navigation';
import DealCard from './DealCard';
import styles from './deals.module.scss';

const skeletonList = (
  <>
    {Array.from(new Array(5)).map((item, index) => (
      <Grid item key={index} xs={12}>
        <GuveryPaper disableHover disableElevation>
          <Skeleton variant="rect" height="263px" />
        </GuveryPaper>
      </Grid>
    ))}
  </>
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

type SearchType = string | number;

const Agreements = (props) => {
  const pathname = props.location.pathname;
  const { isDesktop } = useResponsive();
  const { notification, createMessage, closeMessage } = useNotification();
  let query = useQuery();
  let queryParamSearch: SearchType = query.get('search');
  const searchDialog = useDialog();
  const [search, setSearch] = useState<SearchType>(queryParamSearch);
  const history = useHistory();
  const [path, setPath] = useState(pathname);
  const [loading, setLoading] = useState(true);
  const ITEMS_BY_PAGE = 7;
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [paginable, setPaginable] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [deals, setDeals] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [dealsQuantites, setDealsQuantities] = useState({
    created: 0,
    delivered: 0,
    paid: 0,
    rejected: 0,
  });
  const [allowExtra, setAllowExtra] = useState(false);
  const [partners, setPartners] = useState([]);

  const [currentFilter, setCurrentFilter] = useState<
    'created' | 'paid' | 'delivered' | 'rejected'
  >(null);

  const handleTabFilter = (event: SyntheticEvent, newTabPath) => {
    setPath(newTabPath);
    setFilterByPathname(newTabPath);
    window.scrollTo(0, 0);
  };

  const handleSearchReset = () => {
    history.push({
      pathname: path,
    });
    setSearch(null);
    window.scrollTo(0, 0);
  };

  const fetchDealsQuantity = async () => {
    const res = await dealService.getQuantities();
    if (res.error) return;
    if (!res.data) return;
    setDealsQuantities({
      created: res.data?.deals_created_qty ? res.data.deals_created_qty : 0,
      delivered: res.data?.deals_delivered_qty ? res.data.deals_delivered_qty : 0,
      paid: res.data?.deals_paid_qty ? res.data.deals_paid_qty : 0,
      rejected: res.data?.deals_rejected_qty ? res.data.deals_rejected_qty : 0,
    });
  };

  const fetchDealsExtra = async () => {
    const res = await dealService.getExtras();
    if (res.error) return;
    if (!res.data) return;
    setCarriers(res.data?.carriers);
    setAllowExtra(res.data?.allow_self_deal);
    setPartners(res.data?.available_partnerships);
  };

  const fetchDeals = async () => {
    if (offset > 0) setLoadingPagination(true);
    setLoading(true);
    setPaginable(true);
    if (search) {
      history.push({
        pathname: path,
        search: `?search=${search}`,
      });
    } else {
      history.push({
        pathname: path,
      });
    }
    const res = await dealService.list_by_filter(currentFilter, search, offset);
    if (res.error) return;
    if (!res.data) return;
    if (!res.data.deals) return;
    if (offset > 0) {
      setDeals((prevDeals) => [...prevDeals, ...res.data.deals]);
    } else {
      setDeals(res.data.deals);
    }
    setLoading(false);
    setLoadingPagination(false);
    setHasMore(res.data.deals.length > ITEMS_BY_PAGE - 1);
  };

  const fetchAll = async () => {
    fetchDealsQuantity();
    fetchDealsExtra();
    fetchDeals();
  }

  const handleActionSuccess = async () => {
    if (offset > 0) {
      setOffset(0);
    } else {
      fetchAll();
    }
  }

  const tabsOption = [
    {
      label: `Acuerdos Creados (${dealsQuantites.created})`,
      path: TRAVELER_DEALS_CREATED,
      disable: false,
    },
    {
      label: `Acuerdos Pagados (${dealsQuantites.paid})`,
      path: TRAVELER_DEALS,
      disable: false,
    },
    {
      label: `Acuerdos Entregados (${dealsQuantites.delivered})`,
      path: TRAVELER_DEALS_DELIVERED,
      disable: false,
    },
    {
      label: `Acuerdos Anulados (${dealsQuantites.rejected})`,
      path: TRAVELER_DEALS_CANCELED,
      disable: false,
    },
  ];

  const setFilterByPathname = (pathname) => {
    setDeals([]);
    if (search) setSearch(null);
    if (path === pathname && currentFilter !== null) {
      if (offset > 0) {
        setOffset(0);
        return;
      }
      fetchDeals();
      return;
    }
    setOffset(0);
    if (pathname.toString() === TRAVELER_DEALS_CREATED) {
      setCurrentFilter('created');
      return;
    }
    if (pathname.toString() === TRAVELER_DEALS) {
      setCurrentFilter('paid');
      return;
    }
    if (pathname.toString() === TRAVELER_DEALS_DELIVERED) {
      setCurrentFilter('delivered');
      return;
    }
    if (pathname.toString() === TRAVELER_DEALS_CANCELED) {
      setCurrentFilter('rejected');
      return;
    }
  };

  const observer = useRef() as any;
  const lastMapElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (!paginable) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((offset) => offset + ITEMS_BY_PAGE);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    const pathname = props.location.pathname;
    setFilterByPathname(pathname);
    if (search) setSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentFilter === null) {
      return;
    }
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);

  useEffect(() => {
    if (currentFilter === null) {
      return;
    }
    fetchDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, search]);

  const headerExtra = [
    <Fragment key="0">
      <Button
        disableFocusRipple
        disableElevation
        color="secondary"
        variant="outlined"
        component={RouterLink}
        to={TRAVELER_DEAL_AUTO}>
        Nuevo Auto-Acuerdo
      </Button>
    </Fragment>,
    <Fragment key="1">
      {!search && (
        <Button
          disableFocusRipple
          disableElevation
          color="primary"
          variant="outlined"
          onClick={searchDialog.openDialog}
          endIcon={<IconSearch size={20} />}>
          Buscar
        </Button>
      )}
    </Fragment>,
    <Fragment key="2">
      {search && (
        <Chip
          avatar={
            <Avatar>
              <IconSearch size={16} />
            </Avatar>
          }
          label={search}
          onDelete={handleSearchReset}
        />
      )}
    </Fragment>,
  ];

  return (
    <GuveryLayout>
      <GuveryPageHeader title="Mis Acuerdos" extra={allowExtra && headerExtra} />
      <GuveryTabs
        value={path}
        onChange={handleTabFilter}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={isDesktop ? 'off' : 'on'}
        aria-label="scrollable auto tabs guvery">
        {tabsOption.map((tab, index) => (
          <GuveryTab
            key={index}
            component={RouterLink}
            disabled={tab.disable}
            to={tab.path}
            value={tab.path}
            label={tab.label}
            role={undefined}
          />
        ))}
      </GuveryTabs>

      {search && currentFilter && (
        <Grid item xs={12}>
          {!hasMore ? (
            <p className={styles.emptyHint} style={{ marginTop: '20px' }}>
            Se encontraron {deals.length} acuerdos en la búsqueda, de un total de{' '}
            {dealsQuantites[currentFilter]}. &nbsp; (
            <a
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              className={'guv-text-primary'}
              onClick={handleSearchReset}>
              salir
            </a>
            )
          </p>
          ) : (
            <p className={styles.emptyHint} style={{ marginTop: '20px' }}>
            Se encontraron varios acuerdos en la búsqueda. &nbsp; (
            <a
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              className={'guv-text-primary'}
              onClick={handleSearchReset}>
              salir
            </a>
            )
          </p>
          ) }
          
        </Grid>
      )}

      <GuveryTabPanel value={path} index={TRAVELER_DEALS_CREATED} className={styles.panel}>
        <Grid container spacing={4} className={styles.gridCont}>
          {!loading || loadingPagination ? (
            <>
              {deals.map((deal, index) => {
                if (deals.length === index + 1) {
                  return (
                    <Grid item xs={12} key={index} ref={lastMapElementRef}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={index}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                }
              })}

              {paginable && loadingPagination && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconRefresh size={18} />
                    {'  '}Cargando...
                  </h3>
                </Grid>
              )}

              {paginable && !loadingPagination && !hasMore && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconCheck size={18} />
                    {'  '}Revisaste todos los acuerdos...
                  </h3>
                </Grid>
              )}

              {deals.length <= 0 && (
                <Grid item xs={12}>
                  <GuveryEmpty description="No se encontraron Acuerdos Nuevos...">
                    <p className={styles.emptyHint}>
                      Puedes crear Acuerdos a partir de tus Ofertas que han sido aceptadas.
                    </p>
                    <p className={styles.emptyHint}>
                      Luego espera a que los Acuerdos sean Pagados, para que los puedas comprar.
                    </p>
                    <Button
                      style={{ marginTop: 16 }}
                      disableElevation
                      color="primary"
                      variant="outlined"
                      component={RouterLink}
                      to={TRAVELER_OFFERS}>
                      Ver Mis Ofertas
                    </Button>
                  </GuveryEmpty>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <p>Cargando...</p>
              </Grid>
              {skeletonList}
            </>
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={TRAVELER_DEALS} className={styles.panel}>
        <Grid container spacing={4} className={styles.gridCont}>
          {!loading || loadingPagination ? (
            <>
              {deals.map((deal, index) => {
                if (deals.length === index + 1) {
                  return (
                    <Grid item xs={12} key={index} ref={lastMapElementRef}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        partners={partners}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={index}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        partners={partners}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                }
              })}

              {paginable && loadingPagination && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconRefresh size={18} />
                    {'  '}Cargando...
                  </h3>
                </Grid>
              )}

              {paginable && !loadingPagination && !hasMore && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconCheck size={18} />
                    {'  '}Revisaste todos los acuerdos...
                  </h3>
                </Grid>
              )}

              {deals.length <= 0 && (
                <Grid item xs={12}>
                  <GuveryEmpty description="No se encontraron Acuerdos Pagados...">
                    <p className={styles.emptyHint}>Realiza ofertas para concretar Acuerdos.</p>
                  </GuveryEmpty>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <p>Cargando...</p>
              </Grid>
              {skeletonList}
            </>
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={TRAVELER_DEALS_DELIVERED} className={styles.panel}>
        <Grid container spacing={4} className={styles.gridCont}>
          {!loading || loadingPagination ? (
            <>
              {deals.map((deal, index) => {
                if (deals.length === index + 1) {
                  return (
                    <Grid item xs={12} key={index} ref={lastMapElementRef}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={index}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                }
              })}

              {paginable && loadingPagination && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconRefresh size={18} />
                    {'  '}Cargando...
                  </h3>
                </Grid>
              )}

              {paginable && !loadingPagination && !hasMore && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconCheck size={18} />
                    {'  '}Revisaste todos los acuerdos...
                  </h3>
                </Grid>
              )}

              {deals.length <= 0 && (
                <Grid item xs={12}>
                  <GuveryEmpty description="No se encontraron Acuerdos Entregados..."></GuveryEmpty>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <p>Cargando...</p>
              </Grid>
              {skeletonList}
            </>
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={TRAVELER_DEALS_CANCELED} className={styles.panel}>
        <Grid container spacing={4} className={styles.gridCont}>
          {!loading || loadingPagination ? (
            <>
              {deals.map((deal, index) => {
                if (deals.length === index + 1) {
                  return (
                    <Grid item xs={12} key={index} ref={lastMapElementRef}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={index}>
                      <DealCard
                        deal={deal}
                        carriers={carriers}
                        handleLoading={handleActionSuccess}
                        createMessage={createMessage}
                      />
                    </Grid>
                  );
                }
              })}

              {paginable && loadingPagination && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconRefresh size={18} />
                    {'  '}Cargando...
                  </h3>
                </Grid>
              )}

              {paginable && !loadingPagination && !hasMore && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconCheck size={18} />
                    {'  '}Revisaste todos los acuerdos...
                  </h3>
                </Grid>
              )}

              {deals.length <= 0 && (
                <Grid item xs={12}>
                  <GuveryEmpty description="No se encontraron Acuerdos Cancelados..."></GuveryEmpty>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <p>Cargando...</p>
              </Grid>
              {skeletonList}
            </>
          )}
        </Grid>
      </GuveryTabPanel>
      <SearchDialog
        open={searchDialog.open}
        onClose={searchDialog.closeDialog}
        searchBy="comprador, título, link ó categoría"
        setSearch={setSearch}
        setOffset={setOffset}
      />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </GuveryLayout>
  );
};

export default Agreements;

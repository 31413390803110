import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { FC } from 'react';

import { AUTH_START, USER_PROFILE } from '#root/constants/navigation';
import Context from '#root/Context';
import UserRole, { UserRoleType } from '#root/interfaces/UserRole';
import { UserGroupType } from '#root/interfaces/UserGroup';

interface AuthRouteWithLayoutProps extends RouteProps {
  allowedRoles: UserRoleType[];
  allowedGroup?: UserGroupType;
  component: FC<RouteComponentProps>;
  exact?: boolean;
  layout: FC;
  path: string;
}

const AuthRouteWithLayout = (props: AuthRouteWithLayoutProps) => {
  const { component: Component, layout: Layout, allowedRoles, ...rest } = props;
  const [cookies] = useCookies(['GuveryAuth']);

  return (
    <Context.Consumer>
      {({ auth, session, userRole }) => {
        const isSessionActive = auth && session === cookies.GuveryAuth;
        const userHasRequiredRole =
          allowedRoles.includes(userRole) || userRole === UserRole.admin;

        return (
          <Route
            {...rest}
            render={(matchProps) => {
              if (isSessionActive && userHasRequiredRole) {
                return (
                  <Layout>
                    <Component {...matchProps} />
                  </Layout>
                );
              }

              if (isSessionActive) {
                // window.location.href = URL_PHP_PAGE + 'comprador';
                return (
                  <Redirect
                    to={{
                      pathname: USER_PROFILE,
                      state: { from: matchProps.location, allowedRoles },
                    }}
                  />
                );
              }

              return (
                <Redirect
                  to={{
                    pathname: AUTH_START,
                    state: { from: matchProps.location, allowedRoles },
                  }}
                />
              );
            }}
          />
        );
      }}
    </Context.Consumer>
  );
};

export default AuthRouteWithLayout;

import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Tab as MuiTab } from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab';
// import { LinkProps as RouterLinkProps } from 'react-router-dom';

interface StyledTabProps extends TabProps {
  label: string | React.ReactNode;
  disabled?: boolean;
  component?: string | React.ReactNode;
  to?: string;
}

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontSize: 15,
      fontWeight: 700,
      marginRight: theme.spacing(4),
      // fontFamily: '',
      '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    labelIcon: {
      minHeight: 48,
    },
    wrapper: {
      flexDirection: 'row',
      '& > *:first-child': {
        marginRight: 8,
        marginBottom: '0px !important',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <MuiTab disableRipple {...props} />);

export default Tab;

import { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { format } from 'date-fns';

import { validateURL } from '#root/utils';
import { useNotification } from '#root/hooks';
import { offerService, dealService } from '#root/services';
import { Notification } from '#root/components';
import { GuveryPageHeader, GuveryLayout, GuverySpace } from '#root/ui-components';
import { ServiceOneLogoSvg } from '#root/assets';
import { TRAVELER_OFFERS, TRAVELER_DEALS_CREATED } from '#root/constants/navigation';
import styles from './travelerDealNew.module.scss';
import DealInfo from './DealInfo';
import DealOrders from './DealOrders';
import DealForm from './DealForm';

const baseForm = {
  offer_id: '',
  packaging_type: '',
  delivery_date: '',
  delivery_place: '',
  expiration_date: '',
  deal_orders: [],
};

const reducerQty = (accumulator, current) => Number(accumulator) + Number(current?.can);
const reducerPrice = (accumulator, current) => Number(accumulator) + Number(current?.pre);
const reducerCom = (accumulator, current) => Number(accumulator) + Number(current?.com);

const TravelerDealNew = (props) => {
  const offerId = props.match.params.id;
  const { notification, createMessage, closeMessage } = useNotification();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [offerInfo, setOfferInfo] = useState(null);
  const [dealOrders, setDealOrders] = useState([]);
  const [dealForm, setDealForm] = useState(baseForm);
  const history = useHistory();

  const isProvince = offerInfo?.order?.flag_envio === 1 ? true : false;
  const isOne = offerInfo?.order?.flujo === 'ONE';
  const isDealAllow = offerInfo?.est === '2';

  const qtyTotal = dealForm?.deal_orders?.reduce(reducerQty, 0);
  const priceTotal = dealForm?.deal_orders?.reduce(reducerPrice, 0);
  const comTotal = dealForm?.deal_orders?.reduce(reducerCom, 0);

  const goBack = () => history.push(TRAVELER_OFFERS);

  const handleValidate = () => {
    if (dealForm?.deal_orders?.length > 0) {
      const found = dealForm?.deal_orders.find((e) => !validateURL(e?.url));
      if (found) {
        setSendDisabled(true);
        return;
      }
    }
    if (!dealForm?.delivery_date) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.delivery_place) {
      setSendDisabled(true);
      return;
    }
    if (!dealForm?.expiration_date) {
      setSendDisabled(true);
      return;
    }
    if (qtyTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    if (priceTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    if (comTotal <= 0) {
      setSendDisabled(true);
      return;
    }
    setSendDisabled(false);
  };

  const fetchOfferById = async (id) => {
    setLoading(true);
    const res = await offerService.getOfferById(id);
    if (res.error) return;
    if (!res.data) return;
    const offer = res.data;
      setOfferInfo(offer);
      const order = {
        url: offer?.order?.url,
        can: offer?.can,
        pre: offer?.prerea,
        com: offer?.comvia,
      };
      setDealOrders([order]);
      setDealForm({
        offer_id: offerId,
        packaging_type: offer?.empaque,
        delivery_date: offer?.fecent,
        delivery_place: offer?.lugent,
        expiration_date: offer?.fecmax,
        deal_orders: [order],
      });
    setLoading(false);
  };

  const fetchNewDeal = async () => {
    setSendDisabled(true);
    const body = {
      offer_id: dealForm.offer_id,
      packaging_type: dealForm?.packaging_type,
      delivery_date: format(new Date(dealForm.delivery_date), 'yyyy-MM-dd'),
      delivery_place: dealForm.delivery_place,
      expiration_date: format(new Date(dealForm.expiration_date), 'yyyy-MM-dd'),
      deal_orders: dealForm.deal_orders,
    };
    const res = await dealService.createByTraveler(body);
    if (!res.error) {
      // createMessage.success('Acuerdo creado con éxito');
      history.push(TRAVELER_DEALS_CREATED);
      return;
    }
    createMessage.error('No se pudo crear el acuerdo.');
    setSendDisabled(false);
  };

  useEffect(() => {
    fetchOfferById(offerId);
    return () => {
      setOfferInfo(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  useEffect(() => {
    handleValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTotal, priceTotal, comTotal, dealForm]);

  const title = (
    <span className={styles.title}>
      <span>{`Creando Acuerdo`}</span>
      {isOne && (
        <div className={styles.badgeOne}>
          <img src={ServiceOneLogoSvg} alt="One" />
        </div>
      )}
      {isProvince && (
        <div className={styles.badgeOffice}>
          <span className={styles.text}>Envío Nacional</span>
        </div>
      )}
    </span>
  );

  if (isLoading) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={goBack} />
        <h4>Cargando...</h4>
      </GuveryLayout>
    );
  }

  if (!offerInfo) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={goBack} />
        <h5>Error al obtener los datos de la oferta</h5>
      </GuveryLayout>
    );
  }

  if (!isDealAllow) {
    return <Redirect to={TRAVELER_OFFERS} />;
  }

  return (
    <GuveryLayout>
      <GuveryPageHeader backButton title={title} onBack={goBack} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DealInfo offerInfo={offerInfo} />
        </Grid>
        <Grid item xs={12} md={8}>
          <DealOrders
            dealForm={dealForm}
            setDealForm={setDealForm}
            dealOrders={dealOrders}
            setSendDisabled={setSendDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GuverySpace direction="vertical" size="middle">
            <DealForm isProvince={isProvince} dealForm={dealForm} setDealForm={setDealForm} />
            <Button
              fullWidth
              disableElevation
              disabled={sendDisabled}
              variant="contained"
              color="primary"
              onClick={fetchNewDeal}>
              Guardar y Enviar Acuerdo
            </Button>
          </GuverySpace>
        </Grid>
      </Grid>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </GuveryLayout>
  );
};

export default TravelerDealNew;

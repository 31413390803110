import { CSSProperties, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

import { GuveryPaper } from '#root/ui-components/cards';
import styles from './mainCard.module.scss';

interface MainCardProps {
  children: ReactNode;
  content?: boolean;
  boxShadow?: boolean;
  border?: boolean;
  borderHeader?: boolean;
  className?: string;
  contentClass?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  title?: ReactNode | string | {};
  subheader?: ReactNode | string | {};
  footer?: ReactNode | string | {};
}

const MainCard = forwardRef<HTMLDivElement, MainCardProps>((props, ref) => {
  const {
    children,
    content = true,
    boxShadow,
    border = true,
    borderHeader = true,
    className,
    contentClass,
    style,
    contentStyle,
    title,
    footer,
  } = props;

  return (
    <GuveryPaper
      ref={ref}
      disableHover
      disableBorder={!border}
      disableElevation={!boxShadow}
      className={clsx(styles.root, className)}
      style={style}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <span className={styles.headerTitle}>{title}</span>
        </div>
      </div>
      {title && borderHeader && <hr className={styles.divider} />}
      {content && (
        <div className={clsx(styles.content, contentClass)} style={contentStyle}>
          {children}
        </div>
      )}
      {!content && children}
      {footer && <div className={styles.footer}>{footer}</div>}
    </GuveryPaper>
  );
});

export default MainCard;

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { IconQuestionMark } from '@tabler/icons';
import clsx from 'clsx';

import DealDTO, { DealStatus, IN_STATUS_REJECTED } from '#root/models/DealDTO';
import { useNotification, useAuth, useDialog } from '#root/hooks';
import { dealService } from '#root/services';
import { Notification } from '#root/components';
import { GuveryPageHeader, GuveryLayout, GuverySpace } from '#root/ui-components';
import { GuveryPaper } from '#root/ui-components/cards';
import { ServiceOneLogoSvg } from '#root/assets';
import { TRAVELER_DEAL_DETAIL_RATING, TRAVELER_DEALS } from '#root/constants/navigation';
import styles from './travelerDealDetail.module.scss';
import ProvinceDialog from '#root/views/TravelerDeals/DealCard/ProvinceDialog';
import DealInfo from './DealInfo';
import DealTable from './DealTable';
import Actions from './Actions';
import TimeLine from './Timeline';
import Tools from './Tools';
import Rating from './Rating';

const TravelerDealDetail = (props) => {
  const { usuario } = useAuth();
  const dealIda = props.match.params.ida;
  const isRatingOpen = props.match.path === TRAVELER_DEAL_DETAIL_RATING ? true : false;
  const [isLoading, setLoading] = useState(true);
  const [dealInfo, setDealInfo] = useState<DealDTO>(null);
  const [carriers, setCarriers] = useState([]);
  const [whatsapp, setWhatsapp] = useState(null);
  const { notification, createMessage, closeMessage } = useNotification();
  const provinceDialog = useDialog();

  // eslint-disable-next-line eqeqeq
  const imOwner = usuario.idu == dealInfo?.offer.idu ? true : false;
  const isCreated = dealInfo?.est_n === DealStatus.created;
  const isPaid = dealInfo?.est_n === DealStatus.paid;
  const isPurchased = dealInfo?.est_n === DealStatus.buyed;
  const isReceived = dealInfo?.est_n === DealStatus.recivedTraveler;
  const isDelivered =
    dealInfo?.est_n === DealStatus.confirmedTraveler ||
    dealInfo?.est_n === DealStatus.confirmedShopper ||
    dealInfo?.est_n === DealStatus.payedTraveler;
  const isReady = dealInfo?.est_n === DealStatus.arrived;
  const isOffice = dealInfo?.offer?.order?.flag_envio ? true : false;
  const isCanceled = IN_STATUS_REJECTED.includes(dealInfo?.est_n);

  const isProvincia = dealInfo?.offer?.order?.flag_envio === 1 ? true : false;

  const allowRating = dealInfo?.rating_to_shopper ? true : false;

  const isOne = dealInfo?.offer?.order?.flujo === 'ONE';

  const unclosedChangelogs = dealInfo?.unclosed_changelogs ?? null;

  const history = useHistory();

  let suffix_traveler_deals = '';

  if (isCreated) {
    suffix_traveler_deals = '/creados';
  }
  if (isDelivered) {
    suffix_traveler_deals = '/entregados';
  }
  if (isCanceled) {
    suffix_traveler_deals = '/anulados';
  }
  const goBack = () => history.push(TRAVELER_DEALS + suffix_traveler_deals);

  const fetchDeal = async () => {
    setLoading(true);
    const res = await dealService.travelerDealInfo(dealIda);
    if (!res.error) {
      setDealInfo(res.data?.deal);
      setCarriers(res.data?.carriers);
      setWhatsapp(res.data?.shopper_whatsapp);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = (
    <span className={styles.title}>
      <span>{`Acuerdo #${dealIda}`}</span>
      {isOne && (
        <div className={styles.badgeOne}>
          <img src={ServiceOneLogoSvg} alt="One" />
        </div>
      )}
      {isProvincia && (
        <div className={clsx(styles.badge, styles.office)} onClick={provinceDialog.openDialog}>
          <span className={styles.text}>Envío Nacional</span>
          <IconQuestionMark size={20} style={{ marginLeft: 2 }} />
        </div>
      )}
    </span>
  );

  if (dealInfo === null && !isLoading) {
    return (
      <GuveryLayout>
        <GuveryPageHeader backButton title={title} onBack={goBack} />
        <h5>Error al obtener los datos del acuerdo</h5>
      </GuveryLayout>
    );
  }

  return (
    <GuveryLayout>
      <GuveryPageHeader backButton title={title} onBack={goBack} />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <GuverySpace direction="vertical" size="large">
            {!isLoading ? (
              <DealInfo deal={dealInfo} whatsapp={whatsapp} />
            ) : (
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="319px" />
              </GuveryPaper>
            )}
            {!isLoading ? (
              <DealTable deal={dealInfo} />
            ) : (
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="275px" />
              </GuveryPaper>
            )}
          </GuverySpace>
        </Grid>
        <Grid item xs={12} lg={4}>
          <GuverySpace direction="vertical" size="large">
            {!isLoading ? (
              <>
                {imOwner && (
                  <Actions
                    deal={dealInfo}
                    dealIda={dealInfo?.ida}
                    handleLoading={fetchDeal}
                    createMessage={createMessage}
                    carriers={carriers}
                    isCreated={isCreated}
                    isPaid={isPaid}
                    isPurchased={isPurchased}
                    isReceived={isReceived}
                    isReady={isReady}
                    isOffice={isOffice}
                    unclosedChangelogs={unclosedChangelogs}
                  />
                )}
              </>
            ) : (
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="70px" />
              </GuveryPaper>
            )}
            {allowRating && (
              <Rating
                isOpenDialog={isRatingOpen}
                deal={dealInfo}
                dealIda={dealInfo?.ida}
                isReady={isReady}
                handleLoading={fetchDeal}
                createMessage={createMessage}
              />
            )}
            {!isLoading ? (
              <TimeLine deal={dealInfo} isOne={isOne} isCanceled={isCanceled} />
            ) : (
              <GuveryPaper disableHover disableElevation>
                <Skeleton variant="rect" height="569px" />
              </GuveryPaper>
            )}
            {imOwner && (isPaid || isPurchased || isReceived || isReady) && !isOne && (
              <Tools
                dealIda={dealInfo?.ida}
                handleLoading={fetchDeal}
                createMessage={createMessage}
              />
            )}
          </GuverySpace>
        </Grid>
      </Grid>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
      <ProvinceDialog open={provinceDialog.open} onClose={provinceDialog.closeDialog} />
    </GuveryLayout>
  );
};

export default TravelerDealDetail;

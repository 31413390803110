import { useState, useEffect } from 'react';
import { Button, Grid, Stepper, Step, StepLabel, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useNotification, useResponsive } from '#root/hooks';
import { travelerService } from '#root/services';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  DoneAllIcon,
  WhatsappIcon,
  MedalIcon,
} from '#root/icons';
import { Notification } from '#root/components';
import { GuveryButton, GuverySpace } from '#root/ui-components';
import StepIcon from './StepIcon';
import Connector from './Connector';
import Responsibilities from './Responsibilities';
import Questions from './Questions';
import styles from './travelerTest.module.scss';
import * as QUESTIONS from '#root/constants/questions';

const whatsappLink = {
  href: 'https://api.whatsapp.com/send?phone=51960684330&text=Hola!%20ya%20terminé%20de%20capacitarme,%20mi%20nombre%20es',
  target: '_blank',
  rel: 'noopener noreferrer',
};

const QuestionRadio = (props) => {
  const { fullWidth = false, center, item, value, onChange } = props;
  return (
    <div className={fullWidth && styles.fullWidth}>
      <label className="radio-wrapper">
        <input
          type="radio"
          className={styles.radioInput}
          value={item.value}
          checked={value === item.value}
          onChange={onChange(item.id)}
        />
        <span className={clsx(styles.radioTile, center && styles.center)}>
          <span className={styles.radioLabel}>{item.text}</span>
        </span>
      </label>
    </div>
  );
};

const QuestionCheckbox = (props) => {
  const { fullWidth = false, center, item, value, onChange } = props;
  return (
    <div className={fullWidth && styles.fullWidth}>
      <label className="checkbox-wrapper">
        <input
          type="checkbox"
          name={item.value}
          className={styles.checkboxInput}
          checked={value[item.value]}
          onChange={onChange(item.id)}
        />
        <span className={clsx(styles.checkboxTile, center && styles.center)}>
          <span className={styles.checkboxLabel}>{item.text}</span>
        </span>
      </label>
    </div>
  );
};

const Form = (props) => {
  const { isMobile } = useResponsive();
  const { answers, onAnswersRadioChange, onAnswersCheckboxChange, onAnswersInputChange } =
    props;

  return (
    <GuverySpace direction="vertical" size={24} className={styles.form}>
      <div className={styles.formItem}>
        <div className={styles.formText}>
          <h5>¿Tienes experiencia comprando online en EEUU.? </h5>
          <p className="guv-text-alt-2 guv-regular">
            Sólo cuenta tu experiencia comprando por internet, no de manera física.
          </p>
        </div>
        <Grid container spacing={2} className={styles.answer}>
          {QUESTIONS.questOne.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <QuestionRadio
                fullWidth
                center={isMobile}
                item={item}
                value={answers.one}
                onChange={onAnswersRadioChange}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.formItem}>
        <div className={styles.formText}>
          <h5>¿Tienes cuenta de usuario en Amazon.com?</h5>
        </div>
        <Grid container spacing={2} className={styles.answer}>
          {QUESTIONS.questTwo.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <QuestionRadio
                fullWidth
                center={isMobile}
                item={item}
                value={answers.two}
                onChange={onAnswersRadioChange}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.formItem}>
        <div className={styles.formText}>
          <h5>¿Qué métodos de pago tienes para hacer compras online en EE.UU.?</h5>
          <p className="guv-text-alt-2 guv-regular">
            Guvery nunca te pedirá información sobre tus tarjetas o cuenta de paypal. (Puedes
            marcar más de 1 opción)
          </p>
        </div>
        <Grid container spacing={2} className={styles.answer}>
          {QUESTIONS.questThree.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <QuestionCheckbox
                fullWidth
                center={isMobile}
                item={item}
                value={answers.three}
                onChange={onAnswersCheckboxChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="others"
              label="Otros (especificar):"
              variant="outlined"
              value={answers.three.others}
              onChange={onAnswersInputChange('three')}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.formItem}>
        <div className={styles.formText}>
          <h5>¿Tienes experiencia trayendo pedidos por encargo?</h5>
          <p className="guv-text-alt-2 guv-regular">(Puedes marcar más de 1 opción).</p>
        </div>
        <Grid container spacing={2} className={styles.answer}>
          {QUESTIONS.questFour.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <QuestionCheckbox
                fullWidth
                center={isMobile}
                item={item}
                value={answers.four}
                onChange={onAnswersCheckboxChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="similars"
              label="Conzco otras plataformas (cuáles):"
              variant="outlined"
              value={answers.four.similars}
              onChange={onAnswersInputChange('four')}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.formItem}>
        <div className={styles.formText}>
          <h5>¿Qué tan seguido viajas a EE.UU. (ó de EE.UU. a Perú)?</h5>
        </div>
        <Grid container spacing={2} className={styles.answer}>
          {QUESTIONS.questFive.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <QuestionRadio
                fullWidth
                center={isMobile}
                item={item}
                value={answers.five}
                onChange={onAnswersRadioChange}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={styles.formItem}>
        <div className={styles.formText}>
          <h5>¿Por qué motivos viajas usualmente a EE.UU.?</h5>
          <p className="guv-text-alt-2 guv-regular">(Puedes marcar más de 1 opción).</p>
        </div>
        <Grid container spacing={2} className={styles.answer}>
          {QUESTIONS.questSix.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <QuestionCheckbox
                fullWidth
                center={isMobile}
                item={item}
                value={answers.six}
                onChange={onAnswersCheckboxChange}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="others"
              label="Otros (especificar):"
              variant="outlined"
              value={answers.six.others}
              onChange={onAnswersInputChange('six')}
            />
          </Grid>
        </Grid>
      </div>
    </GuverySpace>
  );
};

const getSteps = () => {
  return ['Perfil del viajero', 'Funciones y compromisos', 'Preguntas frecuentes'];
};

const TravelerTest = (props) => {
  const { step } = props;
  const { isMobile } = useResponsive();
  const { notification, createMessage, closeMessage } = useNotification();
  const [activeStep, setActiveStep] = useState(step);
  const steps = getSteps();
  const [btnDisable, setBtnDisable] = useState(true);
  const [answers, setAnswers] = useState({
    one: '',
    two: '',
    three: {
      paypal: false,
      credit_card_us: false,
      credit_card_pe: false,
      others: '',
    },
    four: {
      carrier: false,
      friends_family: false,
      similars: '',
    },
    five: '',
    six: {
      us_resident: false,
      cabin_crew: false,
      family_couple: false,
      tourism_vacations: false,
      work_business: false,
      others: '',
    },
  });

  const handleAnswersInputChange = (key) => (e) => {
    setAnswers({
      ...answers,
      [key]: {
        ...answers[key],
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleAnswersRadioChange = (key) => (e) => {
    setAnswers({
      ...answers,
      [key]: e.target.value,
    });
  };

  const handleAnswersCheckboxChange = (key) => (e) => {
    setAnswers({
      ...answers,
      [key]: {
        ...answers[key],
        [e.target.name]: e.target.checked,
      },
    });
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReset = () => {
    window.scrollTo(0, 0);
    setActiveStep(0);
  };

  const validateAnswers = () => {
    const { one, two, three, four, five, six } = answers;
    if (!one || !two || !five) {
      setBtnDisable(true);
      return;
    }
    const isThreeValid = Object.values(three).filter((e) => e === true).length >= 1;
    if (!isThreeValid && !three.others) {
      setBtnDisable(true);
      return;
    }
    const isFourValid = Object.values(four).filter((e) => e === true).length >= 1;
    if (!isFourValid && !four.similars) {
      setBtnDisable(true);
      return;
    }
    const isSixValid = Object.values(six).filter((e) => e === true).length >= 1;
    if (!isSixValid && !six.others) {
      setBtnDisable(true);
      return;
    }
    setBtnDisable(false);
  };

  const handleSendTraining = async () => {
    const answer3Array = [];
    for (var key3 in answers.three) {
      if (answers.three[key3] === true) {
        answer3Array.push(key3);
      }
    }
    const answer4Array = [];
    for (var key4 in answers.four) {
      if (answers.four[key4] === true) {
        answer4Array.push(key4);
      }
    }
    const answer6Array = [];
    for (var key6 in answers.six) {
      if (answers.six[key6] === true) {
        answer6Array.push(key6);
      }
    }
    const arg = {
      online_shopping: answers.one,
      amazon_account: answers.two,
      payment_methods: answer3Array,
      payment_methods_others: answers.three.others,
      traveler_experience: answer4Array,
      traveler_experience_similars: answers.four.similars,
      travel_frecuency: answers.five,
      travel_reasons: answer6Array,
      travel_reasons_others: answers.six.others,
    };
    fetchTrainingTest(arg);
  };

  const fetchTrainingTest = async (arg) => {
    createMessage.info('...Enviando datos');
    const res = await travelerService.trainingTest(arg);
    if (!res.info.error) {
      createMessage.success(res.info.message);
      handleNext();
    } else {
      createMessage.error(res.info.message);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Form
            answers={answers}
            onAnswersRadioChange={handleAnswersRadioChange}
            onAnswersCheckboxChange={handleAnswersCheckboxChange}
            onAnswersInputChange={handleAnswersInputChange}
          />
        );
      case 1:
        return <Responsibilities />;
      case 2:
        return <Questions />;
      default:
        return (
          <div>
            <h4>Error</h4>
          </div>
        );
    }
  };

  useEffect(() => {
    validateAnswers();
    // handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Stepper
          alternativeLabel={isMobile}
          activeStep={activeStep}
          connector={<Connector />}
          className={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className={styles.stepLabel} StepIconComponent={StepIcon}>
                <span>{label}</span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={styles.container}>
        {activeStep === steps.length ? (
          <div className={styles.body}>
            <div className={styles.endIcon}>
              <h2>Te falta 1 paso</h2>
              <br />
              <MedalIcon />
              <h3 className="guv-text-error">Contacta a Guvery</h3>
              <p>
                <span className="guv-text-error">
                  Para terminar el proceso de la capacitación y obtener tu medalla.
                </span>
              </p>
              <p className="guv-font-size-5">
                <strong style={{color: '#444'}}>
                  - Debes tener al menos 1 viaje creado.
                  <br />- Crearemos un grupo de whatsapp para ti.
                  <br />- Podrás iniciar con las ofertas.
                </strong>
              </p>
            </div>
            <div className={styles.endButton}>
              <GuveryButton.WhatsApp
                disableElevation
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<WhatsappIcon />}
                {...whatsappLink}>
                Whatsapp con Guvery
              </GuveryButton.WhatsApp>
            </div>
            <div className={styles.endIcon}>
              <br />
              <p>
                De lunes a viernes, de 9am a 6pm (hora de Lima, Perú)
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.body}>{getStepContent(activeStep)}</div>
        )}
      </div>
      {activeStep !== steps.length && (
        <div className={styles.actions}>
          <Button disableElevation disabled={activeStep === 0} onClick={handleBack}>
            <ArrowBackIcon />
          </Button>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            disabled={btnDisable}
            onClick={activeStep === steps.length - 1 ? handleSendTraining : handleNext}
            endIcon={activeStep === steps.length - 1 ? <DoneAllIcon /> : <ArrowForwardIcon />}>
            {activeStep === 0 && 'Guardar y Continuar'}
            {activeStep === 1 && 'Acepto los compromisos'}
            {activeStep === 2 && 'Enviar datos'}
          </Button>
        </div>
      )}
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
};

export default TravelerTest;

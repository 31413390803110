import { Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import { useResponsive } from '#root/hooks';
import { GuveryDialog } from '#root/ui-components';
import { GuveryMainCard } from '#root/ui-components/cards';
import { RankingProgress } from '#root/components';
import styles from './ratingCriteria.module.scss';
import { IconChevronDown, IconCirclePlus } from '@tabler/icons';

const getMinMaxScoring = (arr = []) => {
  const max = Object.entries(arr).reduce((prev, curr) => {
    return prev[1] > curr[1] ? prev : curr;
  });

  const min = Object.entries(arr).reduce((prev, curr) => {
    return prev[1] < curr[1] ? prev : curr;
  });

  return [min, max];
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const RatingCriteriaDialog = (props) => {
  const { open, onClose, levelsInfo, currentScore, scoreExtraMessage = '', travelerOrder } = props;
  const { isMobile } = useResponsive();

  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <GuveryDialog open={open} onClose={onClose} title="Niveles por score y beneficios" content={false}>
      <div className={styles.root} style={{ padding: isMobile ? '0px' : '24px' }}>
        <GuveryMainCard
        title={`Tu score actual: ${currentScore} pts.`}
          content={false}
          borderHeader={false}>
          {levelsInfo?.map((level, index) => {
            index += 100;
            const levelFeatures = level?.features;
            if (levelFeatures && levelFeatures.length > 0) {
              return (
                <Accordion
                  key={index}
                  expanded={expanded === 'panel' + index}
                  onChange={handleChange('panel' + index)}
                  style={{ boxShadow: 'none', borderTop: '1px solid var(--border-color-2)'}}
                  className={clsx(styles.accordion)}
                  >
                  <AccordionSummary
                    expandIcon={<IconChevronDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={clsx(styles.item, level.order === travelerOrder && styles.active)}
                    style={{ border: 'none', margin: '0px', padding: '0px 24px'}}
                    >
                    <Box flexGrow={1} mr={1}>
                      <span className={styles.label}>
                        {level.title} {level?.icon}
                      </span>
                    </Box>
                    <Box flexShrink={0}>
                      <span className={styles.total}>
                        {level.break_min} - {level.break_max} pts
                      </span>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails style={{paddingTop: 0}}>
                  <Box mt={0} width="100%" padding="0px 8px">
                    {levelFeatures.map((feature, index) => (
                      <p key={index} style={{margin: '8px 0px', display: 'flex'}}>
                        <IconCirclePlus size={18} style={{marginRight: 4, color: 'var(--color-text-alt)'}} />
                        <span style={{color: 'var(--color-text-alt)'}}>{feature}</span>
                      </p>
                    ))}
                  </Box>
                  </AccordionDetails>
                </Accordion>
              );
            } else {
              return (
                <div key={index} className={clsx(
                  styles.item,
                  level.order === travelerOrder && styles.active,
                )}>
                  <Box flexGrow={1} mr={1}>
                    <span className={styles.label}>
                      {level.title} {level?.icon}
                    </span>
                  </Box>
                  <Box flexShrink={0}>
                    <span className={styles.total}>
                      {level.break_min} - {level.break_max} pts
                    </span>
                  </Box>
                </div>
              );
            }
          })}
        </GuveryMainCard>        
      </div>
    </GuveryDialog>
  );
};

export default RatingCriteriaDialog;

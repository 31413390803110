import { useState } from 'react';

interface NotificationState {
  message: string;
  state: boolean;
  variant: 'error' | 'info' | 'success' | 'warning';
}

const useNotification = () => {
  const [notification, setNotification] = useState<NotificationState>({
    message: '',
    state: false,
    variant: 'info',
  });

  const error = (text: string) => {
    setNotification({ message: text, variant: 'error', state: true });
  };

  const info = (text: string) => {
    setNotification({ message: text, variant: 'info', state: true });
  };

  const success = (text: string) => {
    setNotification({ message: text, variant: 'success', state: true });
  };

  const warning = (text: string) => {
    setNotification({ message: text, variant: 'warning', state: true });
  };

  const createMessage = {
    error,
    info,
    success,
    warning,
  };

  const closeMessage = () => {
    setNotification({ ...notification, state: false });
  };

  return { notification, createMessage, closeMessage };
};

export default useNotification;

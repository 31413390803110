import { Snackbar } from '@material-ui/core';
import { MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';

import MySnackbarContentWrapper from './MySnackbarContentWrapper';

interface NotificationProps {
  message: string;
  onClose: MouseEventHandler<HTMLButtonElement>;
  state: boolean;
  variant: 'error' | 'info' | 'success' | 'warning';
}

const Notification = (props: NotificationProps) => {
  const { message, onClose, state, variant } = props;

  return createPortal(
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={state}
      autoHideDuration={4500}
      onClose={onClose}>
      <MySnackbarContentWrapper onClose={onClose} variant={variant} message={message} />
    </Snackbar>,
    document.getElementById('guv-notification'),
  );
};

export default Notification;

import { SyntheticEvent, useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useNotification, useResponsive, useDialog } from '#root/hooks';
import { withdrawService } from '#root/services';
import { WithdrawServicesTypes } from '#root/services/withdraw';
import { Notification } from '#root/components';
import {
  GuveryLayout,
  GuveryPageHeader,
  GuveryTab,
  GuveryTabPanel,
  GuveryTabs,
} from '#root/ui-components';
import { GuveryPaper, GuveryEmpty } from '#root/ui-components/cards';
import WithdrawalDTO from '#root/models/WithdrawalDTO';
import {
  TRAVELER_EARNINGS,
  TRAVELER_EARNINGS_UPCOMING,
  TRAVELER_EARNINGS_REQUESTED,
  TRAVELER_EARNINGS_PAID,
} from '#root/constants/navigation';
import styles from './travelerEarnings.module.scss';
import WithdrawCard from './WithdrawCard';
import EarningsNewDialog from './EarningsNewDialog';

type WithdrawsTypes = {
  coming_withdraw: any;
  ready_withdraw: any;
  requested: WithdrawalDTO[];
  paid: WithdrawalDTO[];
};

const skeletonList = (
  <>
    {Array.from(new Array(5)).map((item, index) => (
      <Grid item key={index} xs={12}>
        <GuveryPaper disableHover disableElevation>
          <Skeleton variant="rect" height="205px" />
        </GuveryPaper>
      </Grid>
    ))}
  </>
);

const TravelerEarnings = (props) => {
  const pathname = props.location.pathname;
  const history = useHistory();
  const { notification, createMessage, closeMessage } = useNotification();
  const { isDesktop } = useResponsive();
  const [path, setPath] = useState(
    pathname === TRAVELER_EARNINGS ? TRAVELER_EARNINGS_UPCOMING : pathname,
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [withdraws, setWithdraws] = useState<WithdrawsTypes>({
    coming_withdraw: null,
    ready_withdraw: null,
    requested: [],
    paid: [],
  });
  const [nextWithdrawSequence, setNextWithdrawSequence] = useState(1);
  const [processingTimes, setProcessingTimes] = useState({
    review_laboral_days: 5,
    paid_laboral_days: 5,
  });
  const newEarningsDialog = useDialog();

  const goEarningsRequested = () => {
    history.push(TRAVELER_EARNINGS_REQUESTED);
    setPath(TRAVELER_EARNINGS_REQUESTED);
    handleFetchWithdraws(TRAVELER_EARNINGS_REQUESTED);
  };

  const handlePath = (event: SyntheticEvent, newValue: string | number) => {
    setPath(newValue);
    handleFetchWithdraws(newValue);
  };

  const handleFetchWithdraws = (value) => {
    if (
      value.toString() === TRAVELER_EARNINGS ||
      value.toString() === TRAVELER_EARNINGS_UPCOMING
    ) {
      fetchGetAllWithdraws('coming', value);
      return;
    }
    if (value.toString() === TRAVELER_EARNINGS_REQUESTED) {
      fetchGetAllWithdraws('requested', value);
      return;
    }
    if (value.toString() === TRAVELER_EARNINGS_PAID) {
      fetchGetAllWithdraws('paid', value);
      return;
    }
  };

  const fetchGetAllWithdraws = async (type: WithdrawServicesTypes, viewPath) => {
    setLoading(true);
    const res = await withdrawService.getAllByType(type);
    if (!res.error) {
      const isComing =
        viewPath === TRAVELER_EARNINGS || viewPath === TRAVELER_EARNINGS_UPCOMING;
      const isRequested = viewPath === TRAVELER_EARNINGS_REQUESTED;
      const isPaid = viewPath === TRAVELER_EARNINGS_PAID;
      setWithdraws({
        coming_withdraw:
          isComing && res.data.coming_withdraw ? { deals: res.data.coming_withdraw } : null,
        ready_withdraw:
          isComing && res.data.ready_withdraw ? { deals: res.data.ready_withdraw } : null,
        requested: isRequested ? res.data.withdraws : [],
        paid: isPaid ? res.data.withdraws : [],
      });
      if (isComing || isRequested) {
        setNextWithdrawSequence(res.data.next_withdraw_sequence);
      }
      if (res.data?.processing_times) {
        setProcessingTimes(res.data.processing_times);
      }
    }
    setLoading(false);
  };

  const tabsOption = [
    {
      label: `Próximos`,
      path: TRAVELER_EARNINGS_UPCOMING,
      disable: false,
    },
    {
      label: `Solicitados`,
      path: TRAVELER_EARNINGS_REQUESTED,
      disable: false,
    },
    {
      label: `Pagados`,
      path: TRAVELER_EARNINGS_PAID,
      disable: false,
    },
  ];

  useEffect(() => {
    let active = true;
    const pathname = props.location.pathname;
    active && handleFetchWithdraws(pathname);
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  if (isLoading) {
    return (
      <GuveryLayout>
        <GuveryPageHeader title="Mis Ganancias" />
        <GuveryTabs
          value={path}
          onChange={handlePath}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons={isDesktop ? 'off' : 'on'}
          aria-label="scrollable auto tabs guvery">
          {tabsOption.map((tab, index) => (
            <GuveryTab
              key={index}
              component={RouterLink}
              disabled={tab.disable}
              to={tab.path}
              value={tab.path}
              label={tab.label}
              role={undefined}
            />
          ))}
        </GuveryTabs>
        <div className={styles.panel}>
          <Grid container spacing={3}>
            {skeletonList}
          </Grid>
        </div>
      </GuveryLayout>
    );
  }

  return (
    <GuveryLayout>
      <GuveryPageHeader title="Mis Ganancias" />
      <GuveryTabs
        value={path}
        onChange={handlePath}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={isDesktop ? 'off' : 'on'}
        aria-label="scrollable auto tabs guvery">
        {tabsOption.map((tab, index) => (
          <GuveryTab
            key={index}
            component={RouterLink}
            disabled={tab.disable}
            to={tab.path}
            value={tab.path}
            label={tab.label}
            role={undefined}
          />
        ))}
      </GuveryTabs>
      <GuveryTabPanel value={path} index={TRAVELER_EARNINGS_UPCOMING} className={styles.panel}>
        <Grid container spacing={3}>
          {withdraws.ready_withdraw?.deals?.length > 0 && (
            <Grid item xs={12}>
              <WithdrawCard
                withdrawal={withdraws.ready_withdraw}
                title="Acuerdos entregados"
                allowNewWithdraw
                openNewWithdrawDialog={newEarningsDialog.openDialog}
              />
            </Grid>
          )}
          {withdraws.ready_withdraw?.deals?.length > 0 &&
            withdraws.coming_withdraw?.deals?.length > 0 && (
              <Grid item xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <p style={{ color: 'var(--color-gray)' }}>
                    🔼 Debes entregar estos acuerdos para 🔼 <br />
                    incluirlos en tu siguiente retiro de ganancias.
                    <br />
                  </p>
                </div>
              </Grid>
            )}
          {withdraws.coming_withdraw?.deals?.length > 0 && (
            <Grid item xs={12}>
              <WithdrawCard
                withdrawal={withdraws.coming_withdraw}
                subtitle="Acuerdos por entregar"
                allowDeliveryDeals
              />
            </Grid>
          )}
          {withdraws.ready_withdraw?.deals?.length <= 0 &&
            withdraws.coming_withdraw?.deals?.length <= 0 && (
              <Grid item xs={12}>
                <GuveryEmpty description="Debes entregar acuerdos para retirar tus ganancias." />
              </Grid>
            )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={TRAVELER_EARNINGS_REQUESTED} className={styles.panel}>
        <Grid container spacing={3}>
          {withdraws.requested.map((item, index) => {
            const sequence = nextWithdrawSequence + index - 1;
            return (
              <Grid item xs={12} key={index}>
                <WithdrawCard withdrawal={item} title={`Mi Retiro #${sequence}`} processingTimes={processingTimes} />
              </Grid>
            )
          })}
          {withdraws.requested?.length <= 0 && (
            <Grid item xs={12}>
              <GuveryEmpty description="No tienes retiros solicitados." />
            </Grid>
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={TRAVELER_EARNINGS_PAID} className={styles.panel}>
        <Grid container spacing={3}>
          {withdraws.paid.map((item, index) => {
            const sequence = withdraws.paid.length - index;
            return (
              <Grid item xs={12} key={index}>
                <WithdrawCard withdrawal={item} title={`Mi Retiro #${sequence}`} />
              </Grid>
            )
          })}
          {withdraws.paid?.length <= 0 && (
            <Grid item xs={12}>
              <GuveryEmpty description="No tienes retiros pagados" />
            </Grid>
          )}
        </Grid>
      </GuveryTabPanel>
      <EarningsNewDialog
        open={newEarningsDialog.open}
        onClose={newEarningsDialog.closeDialog}
        nextWithdrawSequence={nextWithdrawSequence}
        createMessage={createMessage}
        handleLoading={goEarningsRequested}
      />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </GuveryLayout>
  );
};

export default TravelerEarnings;

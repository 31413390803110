import { GuveryDialog } from '#root/ui-components';
import styles from './guveryExpressDialog.module.scss';
import { GUVERY_WHATSAPP_NUMBER_FULL } from '#root/constants/variables';
import { shopperService } from '#root/services';
import * as VARIABLES from '#root/constants/variables';
import { Button } from '@material-ui/core';
import { IconBrandWhatsapp, IconBolt } from '@tabler/icons';

interface propsInteface {
    open: boolean;
    onClose: () => void;
    title?: string;
    order?: any;
  }

const GuveryExpressDialog = (props: propsInteface) => {
  const defaultTitle = (<p style={{display: 'flex', alignItems: 'center', gap: 3, fontSize: 20}}><IconBolt style={{color: 'var(--color-express-fee)'}} /> Guvery Express</p>);
  const { open = false, onClose, title = defaultTitle, order = null } = props;

  const WhatsappGuvery = async () => {
    if (!order) return;
    await shopperService.orderSetExpress(order.idp);
    const order_url_encoded = encodeURI(order.url);
    const messageDefault = `Hola Guvery, quiero cotizar un Pedido Express ⚡️ 
    - ${order.id_public} 
    - Precio y comisión referencial: ${order.pre} y ${order.com}
    - Link: ${order_url_encoded}
    `;
    console.log(messageDefault);
    window.open(encodeURI(`https://api.whatsapp.com/send?phone=${GUVERY_WHATSAPP_NUMBER_FULL}&text=${messageDefault}`), "_blank");
    window.location.reload();
  }

  const reloadAfterSubmit = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  const body = (
    <>
      <p>
        El equipo de Guvery puede hacer una cotización exacta de tu pedido para brindarte:
      </p>
      <p style={{textAlign: 'center'}}>
      <strong>[ Total a pagar + Mejor fecha de entrega disponible ]</strong>
      </p>

      <p>
        Basados en información exacta de todos los viajeros activos, sus fechas de entrega, el tiempo de entrega de la tienda y la comisión más justa gracias a miles de pedidos procesados.
      </p>
      <p>
        Adicional a todos los aspectos de seguridad que siempre tendrás con Guvery, le agregamos estos beneficios:
      </p>
      <ul className={styles.benefitsMore}>
        <li>Sabrás que tu pedido llegará en la mejor fecha existente.</li>
        <li>Sabrás que estás pagando la comisión más adecuada.</li>
        <li>Nos aseguramos que el viajero compre tu pedido de inmediato.</li>
        <li>Te brindamos actualizaciones por whatsapp.</li>
      </ul>
      <p>
        También te saltarás las etapas de un proceso regular:
      </p>
      <ul className={styles.benefitsLess}>
        <li>Esperar aprobación.</li>
        <li>Esperar recibir ofertas.</li>
        <li>Comparar ofertas.</li>
        <li>Leer reseñas de los viajeros.</li>
        <li>Conversar con el viajero.</li>
        <li>Aceptar una oferta.</li>
      </ul>
      <p>
        Este servicio tiene un Express Fee ⚡️ desde 2$ a 10$ (dependiendo de la complejidad de la cotización y el valor del producto).
      </p>
      <p style={{color: 'var(--color-text-alt-3)', fontSize: 'var(--font-size-6)'}}>
        * Solicita una cotización sin compromiso.
      </p>
    </>
  );

  const bbody = (
    <>
      <p>
        Solicita al equipo de Guvery una cotización exacta de tu pedido para brindarte el <strong>total a pagar y la mejor fecha de entrega disponible</strong>.
      </p>
      <p style={{color: 'var(--color-text-alt-2)'}}>
        Para lograrlo, usamos la información de todos los viajeros activos, sus fechas de entrega, el tiempo de entrega de la tienda y la comisión más justa (basada en miles de pedidos similares procesados).
      </p>
      <p>
        🔒 Siendo Guvery la plataforma más segura y eficiente para traer compras con viajeros, nuestro servicio ⚡️ Express acorta todas las etapas de un proceso regular y te garantiza que obtendrás la mejor fecha de entrega existente.
      </p>
      <p style={{color: 'var(--color-text-alt-3)', fontSize: 'var(--font-size-6)'}}>
      * Solicita una cotización sin compromiso.
      </p>
      <form action={VARIABLES.URL_PHP_PAGE + "comprador/solicitar_express" } method='post' target='_blank'>
        <input type="hidden" name="order_id" value={order.idp} />
        <Button startIcon={<IconBrandWhatsapp />} component="button" type="submit" onClick={reloadAfterSubmit} variant="contained" color="primary" fullWidth>
          Cotizar
        </Button>
      </form>
    </>
  )

  return (
      <GuveryDialog open={open} onClose={onClose} title={title} closeText='Cerrar'>
      <div className={styles.root}>
        <div className={styles.wrap}>{bbody}</div>
      </div>
    </GuveryDialog>
    )
  ;
};

export default GuveryExpressDialog;

import { useEffect, useState } from 'react';
import { Container, Button, Grid } from '@material-ui/core';

import { useDialog, useNotification } from '#root/hooks';
import { userService } from '#root/services';
import { PaymentCheckoutDialog, Notification } from '#root/components';
import { GuveryPageHeader } from '#root/ui-components';
import { GuveryEmpty } from '#root/ui-components/cards';
import { ShippingDeliveryMan } from '#root/assets';
import ShipmentCard from './ShipmentCard';
import DialogShipmentDetail from './DialogShipmentDetail';
import DialogHelp from './DialogHelp';
import './userShipments.scss';

function UserShipments() {
  const { notification, createMessage, closeMessage } = useNotification();
  const [loading, setLoading] = useState(true);
  const [shipments, setShipments] = useState([]);
  const [shipmentDetail, setShipmentDetail] = useState({});
  const [banks, setBanks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [paymentDialogSource, setPaymentDialogSource] = useState({
    title: 'Pagar Envío',
    id: null,
    amount: '',
    currency: '',
    disabledCurrencies: true,
    disabledPrice: false,
    allowConvert: false,
    allowCredits: false,
    allowCoupon: false,
    amountHelper: 'Importe del envio:',
    showCredits: false,
    stepsArray: [
      'Selecciona el método y el banco.',
      'Escoge la moneda y el monto.',
      'Realiza el pago y confírmalo.',
    ],
    methodsHelper:
      'Usamos estos métodos para evitar el pago de comisión al banco, pues nuestras cuentas son de Lima.',
    paymentType: 'SHIPPING',
  });
  const detailDialog = useDialog();
  const helpDialog = useDialog();
  const paymentDialog = useDialog();

  const handleShipmentDetail = (record) => {
    setShipmentDetail(record);
    detailDialog.openDialog();
  };

  const handleOpenPaymentDialog = (record) => {
    setPaymentDialogSource({
      ...paymentDialogSource,
      id: record.id,
      amount: record.amount,
      currency: record.currency,
      tca: record.tca,
    });
    paymentDialog.openDialog();
  };

  const closePaymentDialog = (record) => {
    fetchUserShipments(record);
    paymentDialog.closeDialog();
  };

  const fetchUserShipments = async () => {
    setLoading(true);
    const res = await userService.shipments();
    // console.log(res.data);
    if (!res.error) {
      setShipments(res.data.shippings);
      setBanks(res.data.payment_checkout_dialog.banks);
      setCurrencies(res.data.payment_checkout_dialog.currencies);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserShipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-shipments guv-main-container">
      <Container maxWidth="lg">
        <GuveryPageHeader
          title="Mis Envíos Nacionales"
          extra={[
            <Button
              key="1"
              disableElevation
              variant="outlined"
              color="secondary"
              onClick={helpDialog.openDialog}>
              Proceso y costos 🔵
            </Button>,
          ]}
        />
        <p className="subtitle">
          Aquí verás todos los envíos nacionales que Guvery está gestionando. El proceso inicia
          desde que el viajero entrega tus pedidos en una oficina autorizada por Guvery. <br />-
          Recuerda que debes confirmar tu dirección dentro de{' '}
          <strong>cada nuevo envío que aparezca aquí.</strong> <br />
          - Si el valor de tus pedidos dentro de un envío, es mayor a 1000 soles, debe ser
          configurado como VALORADO y viajar con un seguro* por parte de OLVA.
          <br />
          <br />
          * El costo del seguro es el 0.6% del monto de Valorización (monto hasta 2999 soles).
          <br />* El costo del seguro es el 2% del monto de Valorización (desde 3000 soles a
          más).
        </p>
        {!loading ? (
          <Grid container spacing={3}>
            {shipments.map((shipment, index) => (
              <Grid key={index} item xs={12}>
                <ShipmentCard
                  shipment={shipment}
                  handleOpenPaymentDialog={handleOpenPaymentDialog}
                  handleShipmentDetail={handleShipmentDetail}
                />
              </Grid>
            ))}
            {shipments.length === 0 && (
              <Grid item xs={12}>
                <GuveryEmpty description="No hay envíos" image={ShippingDeliveryMan} />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h4>Cargando...</h4>
            </Grid>
          </Grid>
        )}
      </Container>
      <DialogHelp open={helpDialog.open} onClose={helpDialog.closeDialog} />
      <DialogShipmentDetail
        open={detailDialog.open}
        onClose={detailDialog.closeDialog}
        data={shipmentDetail}
        handleOpenPaymentDialog={handleOpenPaymentDialog}
        handleLoading={fetchUserShipments}
        createMessage={createMessage}
      />
      <PaymentCheckoutDialog
        open={paymentDialog.open}
        onClose={closePaymentDialog}
        dataSource={paymentDialogSource}
        banks={banks}
        currencies={currencies}
      />
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
    </div>
  );
}

export default UserShipments;

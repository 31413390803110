import { useState, useEffect, SyntheticEvent, useRef, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useResponsive, useNotification, useDialog } from '#root/hooks';
import { shopperService } from '#root/services';
import { DealCard, Notification, OfferDrawer, TravelerReviewsDialog } from '#root/components';
import {
  GuveryLayout,
  GuveryPageHeader,
  GuveryTab,
  GuveryTabPanel,
  GuveryTabs,
} from '#root/ui-components';
import { ShoppingBagIcon } from '#root/icons';
import { IconRefresh, IconCheck } from '@tabler/icons';
import { GuveryPaper, GuveryEmpty } from '#root/ui-components/cards';
import OrderCard from './OrderCard';
import * as ROUTES from '#root/constants/navigation';
import * as VARIABLES from '#root/constants/variables';
import styles from './userOrders.module.scss';

const skeletonGroup = (
  <>
    {Array.from(new Array(4)).map((item, index) => (
      <Grid item key={index} xs={12}>
        <GuveryPaper disableHover disableElevation>
          <Skeleton variant="rect" height="299px" />
        </GuveryPaper>
      </Grid>
    ))}
  </>
);

const emptyDeal = (
  <Grid item xs={12}>
    <GuveryEmpty></GuveryEmpty>
  </Grid>
);

const UserOrders = (props) => {
  const pathname = props.location.pathname;
  const orderId = props.match.params.id;
  const { isDesktop } = useResponsive();
  const { notification, createMessage, closeMessage } = useNotification();
  const [path, setPath] = useState(pathname);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const travelerReviewsDialog = useDialog();
  const [reviewTravelerData, setReviewTravelerData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [tabQuantities, setTabQuantities] = useState({
    open_orders: 0,
    unpaid_deals: 0,
    transit_deals: 0,
    delivered_deals: 0,
    inactive_items: 0,
    refund_dialog: { banks: [] },
  });
  const [tabData, setTabData] = useState({
    open_orders: [],
    one_orders: [],
    waiting_time: 3,
    unpaid_deals: [],
    transit_deals: [],
    delivered_deals: [],
    inactive_deals: [],
    inactive_orders: [],
  });

  const ITEMS_BY_PAGE = 7;
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [paginable, setPaginable] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const [orderObj, setOrderObj] = useState(null);
  const [offerObj, setOfferObj] = useState(null);

  const handleOpenOrderDrawer = (order, offer) => {
    setOrderObj(order);
    setOfferObj(offer);
    setDrawerOpen(true);
  };

  const handlePath = (event: SyntheticEvent, newValue: number) => {
    setPath(newValue);
  };

  const fetchTabQuantities = async () => {
    const res = await shopperService.ordersQuantities();
    if (res.error) return;
    setTabQuantities({ ...res.data });
  };

  const fetchTabData = async () => {
    const filterByPath = {
      '/comprador/mis-pedidos': 'open_orders',
      '/comprador/acuerdos': 'unpaid_deals',
      '/comprador/acuerdos-transito': 'transit_deals',
      '/comprador/pedidos-entregados': 'delivered_deals',
      '/comprador/pedidos-inactivos': 'inactive_items',
    };
    if (!filterByPath[path]) return;
    if (filterByPath[path] === 'delivered_deals') {
      setPaginable(true);
      if (offset > 0) setLoadingPagination(true);
      const res = await shopperService.orders(filterByPath[path], offset);
      if (res.error) return;
      if (!res.data) return;
      if (!res.data.delivered_deals) return;
      if (offset > 0) {
        const updatedDeliveredDeals = {
          delivered_deals: [...tabData.delivered_deals, ...res.data.delivered_deals],
        };
        setTabData({ ...tabData, ...updatedDeliveredDeals });
      } else {
        setTabData({ ...tabData, ...res.data });
      }
      setHasMore(res.data.delivered_deals.length > ITEMS_BY_PAGE - 1);
    } else {
      const res = await shopperService.orders(filterByPath[path]);
      if (res.error) return;
      setTabData({ ...tabData, ...res.data });
    }
    setLoading(false);
    setLoadingPagination(false);
  };

  const fetchAll = async () => {
    setLoading(true);
    await fetchTabQuantities();
    await fetchTabData();
    setLoading(false);
  };

  const tabsOptions = [
    {
      label: `Pedidos Abiertos (${tabQuantities.open_orders})`,
      path: ROUTES.USER_ORDERS,
      disable: false,
    },
    {
      label: (
        <span className={styles.alert}>
          {`Pedidos acordados (${tabQuantities.unpaid_deals})`}
          {tabQuantities.unpaid_deals > 0 && <span className={styles.dot} />}
        </span>
      ),
      path: ROUTES.USER_DEALS,
      disable: false,
    },
    {
      label: `En tránsito (${tabQuantities.transit_deals})`,
      path: ROUTES.USER_DEALS_TRANSIT,
      disable: false,
    },
    {
      label: `Recibidos (${tabQuantities.delivered_deals})`,
      path: ROUTES.USER_DEALS_DELIVERED,
      disable: false,
    },
    {
      label: `Expirados / Anulados (${tabQuantities.inactive_items})`,
      path: ROUTES.USER_DEALS_INACTIVE,
      disable: tabQuantities.inactive_items > 0 ? false : true,
    },
  ];

  const observer = useRef() as any;
  const lastMapElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (!paginable) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((offset) => offset + ITEMS_BY_PAGE);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  useEffect(() => {
    fetchAll();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    fetchTabData();
  }, [offset]);

  return (
    <GuveryLayout>
      <GuveryPageHeader
        title="Mis Pedidos"
        extra={
          <Button
            component="a"
            href={VARIABLES.URL_PHP_PAGE_NEW_ORDER}
            color="primary"
            variant="contained"
            startIcon={<ShoppingBagIcon />}>
            Nuevo Pedido
          </Button>
        }
      />
      {!orderId && (
        <GuveryTabs
          value={path}
          onChange={handlePath}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons={isDesktop ? 'off' : 'on'}
          aria-label="scrollable auto tabs guvery">
          {tabsOptions.map((tab, index) => (
            <GuveryTab
              key={index}
              component={RouterLink}
              disabled={tab.disable}
              to={tab.path}
              value={tab.path}
              label={tab.label}
              role={undefined}
            />
          ))}
        </GuveryTabs>
      )}
      <GuveryTabPanel value={path} index={ROUTES.USER_ORDERS} className={styles.panel}>
        <Grid container spacing={3}>
          {!isLoading ? (
            <>
              {tabData.one_orders.length > 0 &&
                tabData.one_orders.map((order, index) => (
                  <Grid item xs={12} key={index}>
                    <OrderCard
                      order={order}
                      waitingTime={tabData.waiting_time}
                      handleLoading={fetchAll}
                      createMessage={createMessage}
                      orderOne
                      setReviewTravelerData={setReviewTravelerData}
                      travelerReviewsDialog={travelerReviewsDialog}
                    />
                  </Grid>
                ))}
              {tabData.open_orders.length > 0 &&
                tabData.open_orders.map((order, index) => (
                  <Grid item xs={12} key={index}>
                    <OrderCard
                      order={order}
                      waitingTime={tabData.waiting_time}
                      handleLoading={fetchAll}
                      createMessage={createMessage}
                      handleOpenOfferDrawer={handleOpenOrderDrawer}
                      setReviewTravelerData={setReviewTravelerData}
                      travelerReviewsDialog={travelerReviewsDialog}
                    />
                  </Grid>
                ))}
              {tabData.open_orders.length <= 0 && emptyDeal}
            </>
          ) : (
            skeletonGroup
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={ROUTES.USER_DEALS} className={styles.panel}>
        <Grid container spacing={3}>
          {!isLoading ? (
            <>
              {tabData.unpaid_deals.length > 0 &&
                tabData.unpaid_deals.map((deal, index) => (
                  <Grid item xs={12} key={index}>
                    <DealCard
                      state="Sin Pagar"
                      deal={deal}
                      handleLoading={fetchAll}
                      createMessage={createMessage}
                      setReviewTravelerData={setReviewTravelerData}
                      travelerReviewsDialog={travelerReviewsDialog}
                    />
                  </Grid>
                ))}
              {tabData.unpaid_deals.length <= 0 && emptyDeal}
            </>
          ) : (
            skeletonGroup
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={ROUTES.USER_DEALS_TRANSIT} className={styles.panel}>
        <Grid container spacing={3}>
          {!isLoading ? (
            <>
              {tabData.transit_deals.length > 0 &&
                tabData.transit_deals.map((deal, index) => (
                  <Grid item xs={12} key={index}>
                    <DealCard
                      deal={deal}
                      handleLoading={fetchAll}
                      createMessage={createMessage}
                      setReviewTravelerData={setReviewTravelerData}
                      travelerReviewsDialog={travelerReviewsDialog}
                    />
                  </Grid>
                ))}
              {tabData.transit_deals.length <= 0 && emptyDeal}
            </>
          ) : (
            skeletonGroup
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={ROUTES.USER_DEALS_DELIVERED} className={styles.panel}>
        <Grid container spacing={3}>
          {!isLoading || loadingPagination ? (
            <>
              {tabData.delivered_deals.length > 0 &&
                tabData.delivered_deals.map((deal, index) => {
                  if (tabData.delivered_deals.length === index + 1) {
                    return (
                      <Grid item xs={12} key={index} ref={lastMapElementRef}>
                        <DealCard
                          deal={deal}
                          handleLoading={fetchTabData}
                          createMessage={createMessage}
                          setReviewTravelerData={setReviewTravelerData}
                          travelerReviewsDialog={travelerReviewsDialog}
                          avoidReviews
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={12} key={index}>
                        <DealCard
                          deal={deal}
                          handleLoading={fetchTabData}
                          createMessage={createMessage}
                          setReviewTravelerData={setReviewTravelerData}
                          travelerReviewsDialog={travelerReviewsDialog}
                          avoidReviews
                        />
                      </Grid>
                    );
                  }
                })}

              {paginable && loadingPagination && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconRefresh size={18} />
                    {'  '}Cargando...
                  </h3>
                </Grid>
              )}
              {tabData.delivered_deals.length <= 0 && emptyDeal}
              {paginable && !loadingPagination && !hasMore && (
                <Grid item xs={12}>
                  <h3
                    className="guv-text-alt-2"
                    style={{ textAlign: 'center', marginBottom: 30 }}>
                    <IconCheck size={18} />
                    {'  '}Revisaste todos los acuerdos...
                  </h3>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <p>Cargando...</p>
              </Grid>
              {skeletonGroup}
            </>
          )}
        </Grid>
      </GuveryTabPanel>
      <GuveryTabPanel value={path} index={ROUTES.USER_DEALS_INACTIVE} className={styles.panel}>
        <Grid container spacing={3}>
          {!isLoading ? (
            <>
              {tabData.inactive_deals.length > 0 &&
                tabData.inactive_deals.map((deal, index) => (
                  <Grid item xs={12} key={index}>
                    <DealCard
                      deal={deal}
                      state="Acuerdo Anulado"
                      handleLoading={fetchAll}
                      banks={tabQuantities.refund_dialog?.banks}
                      noTraveler
                      createMessage={createMessage}
                      setReviewTravelerData={setReviewTravelerData}
                      travelerReviewsDialog={travelerReviewsDialog}
                    />
                  </Grid>
                ))}
              {tabData.inactive_orders.map((order, index) => (
                <Grid item xs={12} key={index}>
                  <OrderCard
                    order={order}
                    state="Pedido Expirado"
                    handleLoading={fetchAll}
                    createMessage={createMessage}
                    hideOffers
                    setReviewTravelerData={setReviewTravelerData}
                    travelerReviewsDialog={travelerReviewsDialog}
                  />
                </Grid>
              ))}
              {tabData.inactive_orders.length <= 0 && emptyDeal}
            </>
          ) : (
            skeletonGroup
          )}
        </Grid>
      </GuveryTabPanel>
      {notification.state && <Notification onClose={closeMessage} {...notification} />}
      <OfferDrawer
        orderRoot={orderObj}
        offerRoot={offerObj}
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        createMessage={createMessage}
        handleLoading={fetchAll}
      />
      <TravelerReviewsDialog
        open={travelerReviewsDialog.open}
        onClose={travelerReviewsDialog.closeDialog}
        data={reviewTravelerData}
      />
    </GuveryLayout>
  );
};

export default UserOrders;

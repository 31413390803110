import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { ProfileLayout, TravelerLayout } from '#root/layouts';
import * as ROUTES from '#root/constants/navigation';
import {
  AuthValidate,
  NotFound,
  TravelerDashboard,
  TravelerOrders,
  TravelerOffers,
  TravelerTraining,
  TravelerTrips,
  TravelerDeals,
  TravelerDealEdit,
  TravelerDealNew,
  TravelerDealDetail,
  TravelerDealAuto,
  TravelerEarnings,
  TravelerDeliverySetup,
  UserAddress,
  UserChat,
  UserProfile,
  UserShipments,
  UserWallet,
  UserTravelerSettings,
  TravelerTripNew,
  UserCoupons,
  UserDealDetailView,
  UserOrders,
  UserOrderDetail,
  UserOrdersStore,
} from '#root/views';

import AuthRouteWithLayout from './AuthRouteWithLayout';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={ROUTES.AUTH_START} component={AuthValidate} />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDashboard}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DASHBOARD}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerTrips}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_TRIPS}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerTripNew}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_TRIPS_NEW}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerTraining}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_TRAINING}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDeliverySetup}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DELIVERY_SETUP}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerOrders}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_ORDERS}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerOrders}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_ORDERS_BY_ID}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerOffers}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_OFFERS}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerOffers}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_OFFERS_BY_ID}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDeals}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEALS}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDeals}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEALS_CREATED}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDeals}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEALS_DELIVERED}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDeals}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEALS_CANCELED}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDealDetail}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEAL_DETAIL}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDealEdit}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEAL_EDIT}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDealNew}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEAL_NEW}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDealAuto}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEAL_AUTO}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerDealDetail}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_DEAL_DETAIL_RATING}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerEarnings}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_EARNINGS}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerEarnings}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_EARNINGS_UPCOMING}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerEarnings}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_EARNINGS_REQUESTED}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero']}
          component={TravelerEarnings}
          exact
          layout={TravelerLayout}
          path={ROUTES.TRAVELER_EARNINGS_PAID}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_ORDERS}
          layout={ProfileLayout}
          component={UserOrders}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_ORDERS_BY_ID}
          layout={ProfileLayout}
          component={UserOrderDetail}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_ORDERS_BY_ID_BY_OFFER}
          layout={ProfileLayout}
          component={UserOrderDetail}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_DEALS}
          layout={ProfileLayout}
          component={UserOrders}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_DEALS_TRANSIT}
          layout={ProfileLayout}
          component={UserOrders}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_DEALS_DELIVERED}
          layout={ProfileLayout}
          component={UserOrders}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_DEALS_INACTIVE}
          layout={ProfileLayout}
          component={UserOrders}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_DEAL_DETAIL}
          layout={ProfileLayout}
          component={UserDealDetailView}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_DEAL_DETAIL_RATING}
          layout={ProfileLayout}
          component={UserDealDetailView}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_ORDERS_STORE}
          layout={ProfileLayout}
          component={UserOrdersStore}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_ADDRESS}
          layout={ProfileLayout}
          component={UserAddress}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_CHAT}
          layout={ProfileLayout}
          component={UserChat}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_CHAT_ROOM}
          layout={ProfileLayout}
          component={UserChat}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_PROFILE}
          layout={ProfileLayout}
          component={UserProfile}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_WALLET}
          layout={ProfileLayout}
          component={UserWallet}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_SHIPMENTS}
          layout={ProfileLayout}
          component={UserShipments}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_TRAVELER_SETTINGS}
          layout={ProfileLayout}
          component={UserTravelerSettings}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          path={ROUTES.USER_COUPONS}
          layout={ProfileLayout}
          component={UserCoupons}
        />
        <AuthRouteWithLayout
          allowedRoles={['viajero', 'comprador']}
          exact
          component={NotFound}
          layout={TravelerLayout}
          path={ROUTES.NOT_FOUND}
        />
        <Redirect from="*" to={ROUTES.AUTH_START} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
